import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextProps } from './TextProps'

import './Text.scss'

export const Text = ({
  children,
  leftIcon,
  rightIcon,
  className,
  onClick,
  variant = 'regular',
  fontStyle
}: TextProps) => {
  return (
    <div className={`text ${variant} ${className ?? ''} ${fontStyle ?? ''}`} onClick={onClick}>
      {leftIcon ? <span className="prefix"><FontAwesomeIcon icon={leftIcon} /></span> : null}
      <span className="text">{children}</span>
      {rightIcon ? <span className="suffix"><FontAwesomeIcon icon={rightIcon} /></span> : null}
    </div>
  )
}
