import { Translation } from '../Translation'

export const es: Translation = {
  about: 'Sobre nosotros',
  contact: 'Contacto',
  members: 'Miembros',
  epk: {
    biography: {
      title: 'Biografía',
      content: `
        La historia musical de Paumei se remonta a 2012, cuando Guillem y Pau se sumergieron en el mundo de la música
        festiva con Treskuaranta. En esta etapa inicial, se vieron fuertemente influenciados por bandas icónicas del
        panorama musical español como La Raíz, Oques Grasses y La Pegatina. Su estilo, lejos de ser único en aquel
        entonces, era un homenaje vibrante a estas influencias, mezclando géneros y experimentando dentro de un marco
        ya conocido, centrando su energía en crear conciertos llenos de alegría, un ritmo frenético y festividad. Esta
        etapa, rica en experiencias y aprendizajes, sentó las bases para su futuro artístico, y concluyó en 2016,
        dejando una huella indeleble en sus carreras.
      
        Después de la separación de Treskuaranta, Guillem y Pau se embarcaron en aventuras individuales, sumergiéndose
        en proyectos variados y experimentando con nuevos estilos musicales. Este período fue un tiempo de crecimiento
        significativo, donde ampliaron sus horizontes artísticos. La pandemia de 2020, un tiempo de aislamiento y
        reflexión, les brindó la oportunidad de reconectar con su esencia musical, y crearon un álbum que era un
        reflejo de su viaje personal y artístico, una obra inicialmente guardada para ellos y su círculo cercano.
        
        El año 2023 marcó un nuevo capítulo en la historia de Guillem y Pau. Inspirados por los comentarios de su
        círculo cercano, quienes sugirieron compartir su música con un público más amplio, decidieron publicar su
        disco autoeditado bajo el nombre de "Insurrección", presentándose al mundo como Paumei. Ahora, con una
        producción que combina músicas del mundo con la autenticidad de su sonido, Paumei proporciona una experiencia
        musical enriquecedora, una fusión de ritmos y emociones. A través de su música, no solo celebran sus raíces y
        experiencias, sinó que también invitan a su audiencia a un viaje sonoro diverso y emocionante.
      `
    },
    members: {
      title: "Miembros",
      pau: {
        role: "Cantante y compositor",
        biography: `
          Comenzando su andadura en la música con un enfoque en la guitarra, pronto se dio cuenta de que su verdadera
          pasión no se limitaba a un solo género. Optó por no seguir el camino tradicional del jazz y se aventuró en la
          exploración de diversos estilos musicales. En Treskuaranta, inicialmente como guitarrista, se encontró
          asumiendo el papel de vocalista, añadiendo otra dimensión a su repertorio artístico.

          Su curiosidad musical no se detuvo ahí. Se sintió atraído por los instrumentos de viento, y con dedicación
          aprendió a tocar tanto la trompeta como el trombón. Esta ampliación de habilidades enriqueció su expresión
          musical y le abrió nuevas puertas. Tras dejar Treskuaranta, formó parte de Broken Time Family, un grupo de
          Ska Jazz donde tocó la trompeta, y de Juguetes Rotos, una banda de pop-rock en la que incorporó el trombón.

          El año 2020 marcó un punto de inflexión en su carrera. Durante este período, compuso y grabó las piezas que
          ahora son el corazón del disco de Paumei. En este proyecto, demostró su habilidad para manejar una variedad
          de roles, grabando las voces, las guitarras, el bajo, así como sus ya característicos instrumentos de viento,
          la trompeta y el trombón. Su viaje musical es un testimonio de su pasión por la música y su constante deseo
          de crecer y evolucionar como artista.
        `
      }
    }
  }
}
