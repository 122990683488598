import React from 'react'
import { LanguageSelector, Paumei } from '../../../../components'
import { Text } from '../../../../atoms'

import './EpkHeader.scss'
import { useNavigate } from 'react-router-dom'

export const EpkHeader = () => {

  const navigate = useNavigate()

  const onPaumeiClick = () => {
    navigate('/')
  }

  return (
    <header className="epk-header">
      <Paumei onClick={onPaumeiClick} />
      <Text variant="h2">Electronic Press Kit</Text>

      <div className="epk-header_language-selector">
        <LanguageSelector />
      </div>
    </header>
  )
}
