import React from 'react'
import { EpkSection } from '../../components'
import { useLocalisation } from '../../../../localisation'
import { FormattedText } from '../../../../atoms'

export const EpkBiographySection = () => {

  const { translation } = useLocalisation()

  return (
    <EpkSection title={translation.epk.biography.title}>
      <FormattedText variant="p">
        {translation.epk.biography.content}
      </FormattedText>
    </EpkSection>
  )
}
