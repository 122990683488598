import React from 'react'

import './Album.scss'

export const Album = () => {
  return (
    <div className="album">
      <div className="album-image-container">
        <img src="/images/insurreccion.jpeg" alt="Insurrecci&oacute;n" />
        <div className="album-title">Insurrecci&oacute;n</div>
      </div>
    </div>
  )
}
