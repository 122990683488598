import React, { ReactNode } from 'react'
import { Text } from '../../../../atoms'

import './EpkMember.scss'

interface EpkMemberProps {
  name: string
  imageUrl: string
  role: string
  children?: ReactNode | ReactNode[]
}

export const EpkMember = ({ name, imageUrl, role, children }: EpkMemberProps) => {
  return (
    <div className="epk-member">
      <div className="epk-member_image">
        <img src={imageUrl} alt={name} />
      </div>
      <div className="epk-member_info">
        <Text variant="h3">{name}</Text>
        <Text variant="regular" fontStyle="italic">{role}</Text>
        <div className="epk-member_content">
          {children}
        </div>
      </div>
    </div>
  )
}
