import React from 'react'
import { Text } from '../'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import './Email.scss'

interface EmailProps {
  children?: string
}

export const Email = ({ children }: EmailProps) => {

  const onClick = () => {
    window.open(`mailto:${children}`)
  }

  return (
    <Text className="email" leftIcon={faEnvelope} onClick={onClick}>{children}</Text>
  )
}
