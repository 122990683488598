import React, { useState } from 'react'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../atoms'
import { useLocalisation } from '../../localisation'
import { LanguageSelector } from '../LanguageSelector'
import { Drawer } from '../Drawer'

import './ResponsiveMenu.scss'

export const ResponsiveMenu = () => {
  const { translation } = useLocalisation()
  const [isOpen, setIsOpen] = useState(false)

  const close = () => {
    setIsOpen(false)
  }

  const onMenuButtonClick = () => {
    setIsOpen(true)
  }

  return (
    <div className="responsive-menu">
      <Button leftIcon={faBars} onClick={onMenuButtonClick}/>

      <Drawer isOpen={isOpen} onCloseClick={close} position="left">
        <div className="buttons">
          <Button>{translation.about}</Button>
          <Button>{translation.members}</Button>
        </div>
        <LanguageSelector className="responsive-menu_language-selector" onLanguageChanged={close}/>
      </Drawer>
    </div>
  )
}
