import React, { ReactNode } from 'react'
import { Text } from '../../../../atoms'

import './EpkSection.scss'

interface EpkSectionProps {
  title: string
  children?: ReactNode | ReactNode[]
}

export const EpkSection = ({ title, children }: EpkSectionProps) => {
  return (
    <div className="epk-section">
      <Text className="title" variant="h1">{title}</Text>

      <div className="content">
        {children}
      </div>
    </div>
  )
}
