import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Text } from '../Text'

import './Button.scss'

interface ButtonProps {
  children?: string
  leftIcon?: IconProp
  rightIcon?: IconProp
  onClick?: () => void
}

export const Button = ({children, leftIcon, rightIcon, onClick}: ButtonProps) => {
  return (
    <Text
      className="button"
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      onClick={onClick}
    >
      {children}
    </Text>
  )
}
