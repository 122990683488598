import { Translation } from '../Translation'

export const en: Translation = {
  about: 'About',
  contact: 'Contact',
  members: 'Members',
  epk: {
    biography: {
      title: 'Biography',
      content: `
        The musical history of Paumei dates back to 2012 when Guillem and Pau immersed themselves in the world of
        festive music with Treskuaranta. In this initial stage, they were strongly influenced by iconic bands from the
        Spanish music scene such as La Raíz, Oques Grasses, and La Pegatina. Their style, far from being unique at that
        time, was a vibrant homage to these influences, mixing genres and experimenting within a known framework,
        focusing their energy on creating concerts full of joy, frenetic rhythm, and festivity. This stage, rich in
        experiences and learning, laid the foundation for their future artistic path, and concluded in 2016, leaving an
        indelible mark on their careers.

        After the separation of Treskuaranta, Guillem and Pau embarked on individual adventures, immersing themselves
        in various projects and experimenting with new musical styles. This period was a time of significant growth,
        where they broadened their artistic horizons. The 2020 pandemic, a time of isolation and reflection, provided
        them with the opportunity to reconnect with their musical essence, and they created an album that was a
        reflection of their personal and artistic journey, a work initially kept for themselves and their close circle.

        The year 2023 marked a new chapter in the story of Guillem and Pau. Inspired by the comments of their close
        circle, whosuggested sharing their music with a broader audience, they decided to release their self-published
        album under the name "Insurrección", introducing themselves to the world as Paumei. Now, with a production that
        combines world music with the authenticity of their sound, Paumei provides an enriching musical experience, a
        fusion of rhythms and emotions. Through their music, they not only celebrate their roots and experiences but
        also invite their audience to a diverse and exciting sonic journey.
      `
    },
    members: {
      title: "Members",
      pau: {
        role: "Singer & songwriter",
        biography: `
          Beginning his foray into music with a focus on the guitar, he soon realized that his true passion was not
          confined to a single genre. Choosing to step away from the traditional jazz path, he ventured into exploring
          a variety of musical styles. Initially a guitarist in Treskuaranta, he found himself taking on the role of
          vocalist, adding another facet to his artistic repertoire.

          His musical curiosity did not stop there. He was drawn to wind instruments, and with dedication, he learned
          to play both the trumpet and the trombone. This expansion of skills enriched his musical expression and
          opened new avenues. After leaving Treskuaranta, he became part of Broken Time Family, a Ska Jazz group where
          he played the trumpet, and Juguetes Rotos, a pop-rock band where he incorporated the trombone.

          The year 2020 marked a turning point in his career. During this time, he composed and recorded the pieces
          that now form the heart of Paumei's album. In this project, he showcased his ability to handle a variety of
          roles, recording the vocals, guitars, bass, as well as his now signature wind instruments, the trumpet and
          trombone. His musical journey is a testament to his passion for music and his constant desire to grow and
          evolve as an artist.
        `
      }
    }
  }
}
