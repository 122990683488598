import React from 'react'
import { ActiveAlbum, Footer, Header } from '../../components'

import './MainPage.scss'

export const MainPage = () => {
  return (
    <div className="main-page">
      <Header />
      <ActiveAlbum />
      <Footer />
    </div>
  )
}
