import React from 'react'
import { EpkHeader, EpkSection } from './components'
import { EpkBiographySection, EpkMembersSection, EpkMusicSection } from './sections'

import './EpkPage.scss'

export const EpkPage = () => {
  return (
    <div className="epk">
      <EpkHeader />
      <EpkBiographySection />
      <EpkMembersSection />
      <EpkSection title="Photos" />
      <EpkMusicSection />
      {/*<EpkSection title="Analytics" />*/}
      <EpkSection title="Contact" />
    </div>
  )
}
