import { Translation } from '../Translation'

export const ca: Translation = {
  about: 'Sobre nosaltres',
  contact: 'Contacte',
  members: 'Membres',
  epk: {
    biography: {
      title: 'Biografia',
      content: `
        La història musical de Paumei es remunta al 2012, quan Guillem i Pau es van submergir en el món de la música
        festiva amb Treskuaranta. En aquesta etapa inicial, van ser fortament influenciats per bandes icòniques de
        l'escena musical espanyola com La Raíz, Oques Grasses i La Pegatina. El seu estil, lluny de ser únic en aquell
        moment, era un homenatge vibrant a aquestes influències, barrejant gèneres i experimentant dins d'un marc ja
        conegut, centrant la seva energia en crear concerts plens d'alegria, ritme frenètic i festivitat. Aquesta
        etapa, rica en experiències i aprenentatges, va posar les bases per al seu futur artístic, i va concloure el
        2016, deixant una empremta indeleble en les seves carreres.

        Després de la separació de Treskuaranta, Guillem i Pau es van embarcar en aventures individuals, submergint-se
        en projectes variats i experimentant amb nous estils musicals. Aquest període va ser un temps de creixement
        significatiu, on van ampliar els seus horitzons artístics. La pandèmia del 2020, un temps d'aïllament i
        reflexió, els va brindar l'oportunitat de reconnectar amb la seva essència musical, i van crear un àlbum que
        era un reflex del seu viatge personal i artístic, una obra inicialment guardada per a ells i el seu cercle
        proper.

        L'any 2023 va marcar un nou capítol en la història de Guillem i Pau. Inspirats pels comentaris del seu cercle
        proper, que van suggerir compartir la seva música amb un públic més ampli, van decidir publicar el seu disc
        autoeditat sota el nom d'"Insurrecció", presentant-se al món com a Paumei. Ara, amb una producció que combina
        músiques del món amb l'autenticitat del seu so, Paumei proporciona una experiència musical enriquidora, una
        fusió de ritmes i emocions. A través de la seva música, no només celebren les seves arrels i experiències, sinó
        que també conviden al seu públic a un viatge sonor divers i emocionant.
      `
    },
    members: {
      title: "Membres",
      pau: {
        role: "Cantant i compositor",
        biography: `
          Començant la seva incursió en la música amb un enfocament en la guitarra, aviat va adonar-se que la seva
          veritable passió no es limitava a un sol gènere. Escollint allunyar-se del camí tradicional del jazz, es va
          aventurar a explorar una varietat d'estils musicals. Inicialment guitarrista a Treskuaranta, es va trobar
          assumint el rol de vocalista, afegint una altra faceta al seu repertori artístic.

          La seva curiositat musical no es va aturar aquí. Es va sentir atret pels instruments de vent, i amb dedicació
          va aprendre a tocar tant la trompeta com el trombó. Aquesta expansió d'habilitats va enriquir la seva
          expressió musical i va obrir noves vies. Després de deixar Treskuaranta, va formar part de Broken Time
          Family, un grup de Ska Jazz on tocava la trompeta, i de Juguetes Rotos, una banda de pop-rock on va
          incorporar el trombó.

          L'any 2020 va marcar un punt d'inflexió en la seva carrera. Durant aquest període, va composar i va gravar
          les peces que ara formen el cor de l'àlbum de Paumei. En aquest projecte, va demostrar la seva habilitat per
          manejar una varietat de rols, gravant les veus, les guitarres, el baix, així com els seus ja característics
          instruments de vent, la trompeta i el trombó. El seu viatge musical és un testimoni de la seva passió per la
          música i del seu constant desig de créixer i evolucionar com a artista.
        `
      }
    }
  }
}
