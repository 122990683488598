import { createContext, useContext } from 'react'
import { Language } from './Language'
import { Translation } from './Translation'

interface LocalisationState {
  currentLanguage: Language
  switchLanguage: (newLanguage: Language) => void
  translation: Translation
}

export const LocalisationContext = createContext<LocalisationState>({} as any)

export const useLocalisation = () => useContext(LocalisationContext)
