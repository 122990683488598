import React, { useState } from 'react'
import { faAngleDown, faAngleLeft, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../atoms'
import { useLocalisation } from '../../localisation'
import { Language } from '../../localisation'

import './LanguageSelector.scss'

interface LanguageSelectorProps {
  className?: string
  onLanguageChanged?: () => void
}

export const LanguageSelector = ({ className, onLanguageChanged }: LanguageSelectorProps) => {

  const { currentLanguage, switchLanguage } = useLocalisation()
  const [isOpen, setIsOpen] = useState(false)

  const onCurrentLanguageClick = () => {
    setIsOpen(!isOpen)
  }

  const onLanguageClick = (language: Language) => {
    setIsOpen(false)
    switchLanguage(language)
    onLanguageChanged?.()
  }

  return (
    <div className={`language-selector ${className ?? ''}`}>
      <Button leftIcon={faGlobe} rightIcon={isOpen ? faAngleLeft : faAngleDown} onClick={onCurrentLanguageClick}>
        {currentLanguage}
      </Button>
      <div className="selector-position">
        {isOpen
          ? (
            <div className="selector">
              <Button onClick={() => onLanguageClick('en')}>EN</Button>
              <Button onClick={() => onLanguageClick('es')}>ES</Button>
              <Button onClick={() => onLanguageClick('ca')}>CA</Button>
            </div>
          )
          : null
        }
      </div>
    </div>
  )
}
