import React from 'react'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { Text } from '../Text'

import './Phone.scss'

interface PhoneProps {
  children?: string
}

export const Phone = ({ children }: PhoneProps) => {

  const onClick = () => {
    window.open(`https://wa.me/${children?.replaceAll(' ', '')}`)
  }

  return (
    <Text className="phone" leftIcon={faPhone} onClick={onClick}>{children}</Text>
  )
}
