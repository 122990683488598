import React from 'react'
import { EpkSection } from '../../components'
import { EpkMember } from './EpkMember'
import { FormattedText, Text } from '../../../../atoms'
import { useLocalisation } from '../../../../localisation'

import './EpkMembersSection.scss'

export const EpkMembersSection = () => {
  const { translation } = useLocalisation()

  return (
    <EpkSection title="Members">
      <div className="epk-members">
        <EpkMember
          name="Pau Torrents"
          role={translation.epk.members.pau.role}
          imageUrl="/images/photo_pau.jpeg"
        >
          <FormattedText variant="p2">
            {translation.epk.members.pau.biography}
          </FormattedText>
        </EpkMember>
        <EpkMember
          name="Guillem Eloi Baqués"
          role="Batería"
          imageUrl="/images/photo_guillem.jpeg"
        >
          <Text variant="p2">
            Nullam malesuada augue sed suscipit congue. Sed fringilla mattis dolor, vitae gravida est mollis nec. Nunc pretium feugiat arcu quis hendrerit. Maecenas sollicitudin, metus a fermentum dictum, tellus velit hendrerit odio, sed posuere arcu turpis ut urna. Aenean commodo dui eu nisi iaculis egestas eget id magna. Fusce vel est eu lorem porttitor bibendum non sit amet velit. Aenean eleifend iaculis lorem, nec blandit nisi dignissim viverra. Donec condimentum dignissim sollicitudin. Etiam condimentum velit ut laoreet placerat. Ut sodales fringilla sagittis. Ut varius elementum facilisis. Maecenas eget tincidunt tortor, et aliquam nibh. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam sed lacus sed orci scelerisque tristique vel in nisi.
          </Text>
        </EpkMember>
      </div>
    </EpkSection>
  )
}
