import React from 'react'
import { Text } from '../../atoms'

import './Copyright.scss'

export const Copyright = () => {
  return (
    <div className="copyright">
      <Text>Copyright &copy; 2024 Paumei</Text>
    </div>
  )
}
