import React, { ReactNode } from 'react'
import { Button } from '../../atoms'
import { faClose } from '@fortawesome/free-solid-svg-icons'

import './Drawer.scss'

interface DrawerProps {
  isOpen?: boolean
  onCloseClick?: () => void
  children?: ReactNode | ReactNode[]
  position?: 'left' | 'bottom'
}

export const Drawer = ({ isOpen, onCloseClick, children, position = 'left' }: DrawerProps) => {
  return (
    <div className={`drawer ${position}`}>
      <div className={`drawer-backdrop ${isOpen ? 'open' : ''}`} onClick={onCloseClick} />
      <div className={`drawer-container ${isOpen ? 'open' : ''}`}>
        <div className="drawer-close">
          <Button rightIcon={faClose} onClick={onCloseClick} />
        </div>

        {children}
      </div>
    </div>
  )
}