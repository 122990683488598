import React from 'react'
import { ActiveAlbumLinks } from './ActiveAlbumLinks'
import { Album } from '../'

import './ActiveAlbum.scss'

export const ActiveAlbum = () => {
  return (
    <main className="active-album">
      <aside className="links">
        <ActiveAlbumLinks />
      </aside>
      <section className="album-column">
        <div className="album-container">
          <Album />
        </div>
      </section>
      <aside className="right"></aside>
    </main>
  )
}
