import React, { ReactNode, useEffect, useState } from 'react'
import { LocalisationContext } from './LocalizationContext'
import { Language } from './Language'
import { Translation } from './Translation'
import { translations } from './translations'
import { getItem, saveItem } from '../storage/storage'

interface LocalisationProviderProps {
  children?: ReactNode
}

const languageStorageKey = 'language'

export const LocalisationProvider = ({ children }: LocalisationProviderProps) => {

  const [currentLanguage, setCurrentLanguage] = useState<Language>(() => {
    const savedItem = getItem(languageStorageKey)
    if (!savedItem) {
      return 'en'
    }
    return savedItem!! as Language
  })
  const [translation, setTranslation] = useState<Translation>(translations.en)

  useEffect(() => {
    setTranslation(translations[currentLanguage])
  }, [currentLanguage])

  const switchLanguage = (newLanguage: Language) => {
    setCurrentLanguage(newLanguage)
    saveItem(languageStorageKey, newLanguage)
  }

  return (
    <LocalisationContext.Provider value={{
      currentLanguage,
      switchLanguage,
      translation
    }}>
      {children}
    </LocalisationContext.Provider>
  )
}
