import React from 'react';
import { LocalisationProvider } from './localisation'
import { EpkPage, MainPage } from './pages'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainPage />,
  },
  {
    path: '/epk',
    element: <EpkPage />,
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  }
])

export const App = () => {
  return (
    <LocalisationProvider>
      <RouterProvider router={router} />
    </LocalisationProvider>
  );
}
