import React from 'react'
import { Email, Phone } from '../../atoms'

import './Contact.scss'

export const Contact = () => {
  return (
    <div className="contact">
      <Phone>+34 605 53 23 35</Phone>
      <Email>contact@paumei.com</Email>
    </div>
  )
}
