import React, { useMemo } from 'react'
import { Text } from './Text'
import { TextProps } from './TextProps'

export const FormattedText = ({ children, variant = 'p' }: TextProps) => {

  const formattedContent = useMemo(() => {
    if (!children) return null

    return children
      .split(/(\n( *))\1+/)
      .filter(paragraph => /\S/.test(paragraph))
      .map(paragraph => (
        <Text variant={variant}>{paragraph}</Text>
      ))
  }, [children, variant])

  return (<>{formattedContent}</>)
}
