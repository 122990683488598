import React, { useState } from 'react'
import { Button } from '../../atoms'
import { useLocalisation } from '../../localisation'
import { Drawer } from '../Drawer'
import { Contact } from './Contact'
import { Copyright } from './Copyright'

import './Footer.scss'

export const Footer = () => {
  const { translation } = useLocalisation()

  const [isOpen, setIsOpen] = useState(false)

  const onContactClick = () => {
    setIsOpen(true)
  }

  const onDrawerClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <footer className="footer desktop">
        <Contact />
        <Copyright />
      </footer>
      <footer className="footer mobile">
        <Copyright />

        <div className="button-container">
          <Button onClick={onContactClick}>{translation.contact}</Button>
        </div>

        <Drawer isOpen={isOpen} position="bottom" onCloseClick={onDrawerClose}>
          <Contact />
        </Drawer>
      </footer>
    </>
  )
}
