import React from 'react'

import './AlbumLink.scss'

interface AlbumLinkProps {
  type: 'spotify' | 'apple' | 'youtube'
  id: string
}

export const AlbumLink = ({ type, id }: AlbumLinkProps) => {

  const onClick = () => {
    const url = urlTemplateByType[type].replace('{id}', id)
    window.open(url, '_blank')
  }

  return (
    <div className="album-link" onClick={onClick}>
      <img src={logoByType[type]} alt={type} />
    </div>
  )
}

const logoByType = {
  spotify: '/images/spotify-logo-white.png',
  apple: '/images/apple-logo-white.png',
  youtube: '/images/youtube-logo-white.png',
}

const urlTemplateByType = {
  spotify: 'https://open.spotify.com/album/{id}',
  apple: 'https://music.apple.com/es/album/{id}',
  youtube: 'https://music.youtube.com/playlist?list={id}',
}
