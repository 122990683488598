import React from 'react'
import { AlbumLink } from '../AlbumLink'

import './ActiveAlbumLinks.scss'

export const ActiveAlbumLinks = () => {
  return (
    <div className="active-album-links">
      <div className="links-container">
        <AlbumLink type="spotify" id="5AgJNG0pjXUhc0TfTG66LR" />
        <AlbumLink type="apple" id="1688009344" />
        <AlbumLink type="youtube" id="OLAK5uy_kWugcmZbE248MxZ5BfqSQe01_d3H5YNmI" />
      </div>
    </div>
  )
}
