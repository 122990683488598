import React from 'react'
import { Button } from '../../atoms'
import { LanguageSelector, Paumei, ResponsiveMenu } from '../'
import { useLocalisation } from '../../localisation'

import './Header.scss'

export const Header = () => {

  const { translation } = useLocalisation()

  return (
    <header className="header">
      <div className="menu-desktop">
        <Button>{translation.about}</Button>
        <Button>{translation.members}</Button>
      </div>
      <div className="menu-mobile">
        <ResponsiveMenu />
      </div>
      <div className="header-paumei">
        <Paumei />
      </div>
      <div className="language-desktop">
        <LanguageSelector />
      </div>
    </header>
  )
}
