import React from 'react'
import { EpkSection } from '../../components'
import { Album } from '../../../../components'

import './EpkMusicSection.scss'

export const EpkMusicSection = () => {
  return (
    <EpkSection title="Music">
      <div className="epk-music">
        <div className="epk-music_album">
          <Album />
        </div>
      </div>
    </EpkSection>
  )
}
