import React from 'react'

import './Paumei.scss'

interface PaumeiProps {
  onClick?: () => void
}

export const Paumei = ({ onClick }: PaumeiProps) => {

  return (
    <div className={`paumei ${onClick ? 'clickable' : ''}`} onClick={onClick}>
      <span>PAU</span><span>MEI</span>
    </div>
  )
}
